import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Nursery from '../assets/pdf/Nursery.pdf'
import Jrkg from '../assets/pdf/jrkg.pdf'
import Srkg from '../assets/pdf/srkg.pdf'
import http from '../_api/http'

const PrePrimary = () => {
  const [loading, setLoading] = useState(true)
  const [imageDescs, setImageDesc] = useState([])
  const [standard, setStandard] = useState([])
  const [pdf, setPdf] = useState([])
  const PUBLIC_URL = process.env.REACT_APP_APIURL
  useEffect(() => {
    // alert(process.env.REACT_APP_APIURL);
    http
      .get(
        'api/imageupload/ApiImageUploadList?image_subcategory_id=8&section=Pri-primary'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          setImageDesc(res.data.image)
          setLoading(false)
        }
      })

    http
      .get(
        'api/imageupload/ApiImageUploadList?image_subcategory_id=9&section=Pri-primary'
      )
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          setStandard(res.data.image)
          setLoading(false)
        }
      })

    // alert(process.env.REACT_APP_APIURL);
    http.get('api/pdf/ApiPdfList?pdf_for_type=2').then(res => {
      //  console.log(res);
      // debugger
      if (res.data.status === 200) {
        setPdf(res.data.pdf)
        // setLoading(false);
      }
    })
  }, [])

  return (
    <div>
      <section className='team-details'>
        <div className='container'>
          <div className='row justify-content-between'>
            {loading ? (
              <h4>View Pri-Primary Loading...</h4>
            ) : (
              <>
                {imageDescs.map((item, i) => (
                  <div className='row' key={i}>
                    <div className='col-lg-6'>
                      <div className='team-one__single'>
                        <div className='team-one__image'>
                          <img
                            src={
                              PUBLIC_URL + 'uploadimage/' + item.image
                            }
                            alt='' 
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='team-details__content'>
                        <h2 className='team-details__title title'>
                          {item.image_title}
                        </h2>
                        <p className='team-details__text'>
                          {item.image_description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
      <section className='course-one course-page'>
        <div className='container'>
          <div className='row'>
            {loading ? (
              <h4>View Pri-Primary Standard Loading...</h4>
            ) : (
              <>
                {standard.map((item, i) => (
                  <>
                    <div className='col-lg-4' key={i}>
                      <div className='course-one__single'>
                        <div className='course-one__image'>
                          <img
                            src={
                              PUBLIC_URL + 'uploadimage/' + item.image
                            }
                            alt='' height={200} width={150}
                          />
                          <i className='far fa-heart'></i>
                        </div>
                        <div className='course-one__content'>
                          <div className='course-one__admin'>
                            <Link to='#'>{item.image_title}</Link>
                          </div>
                          <h2 className='course-one__title'>
                            <Link to='#'>{item.image_description}</Link>
                          </h2>
                          {pdf
                            .filter(
                              f => f.pdf_select_for == item.image_description
                            )
                            .map((item, i) => (
                              <a
                                key={i}
                                href={PUBLIC_URL + 'files/' + item.name}
                                target='_blank'
                                rel='noreferrer'
                                className='course-one__link'
                              >
                                Book List & Syllabus
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default PrePrimary
