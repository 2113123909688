import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeaderPri from "../components/PageHeaderPri";
import Footer from "../components/Footer"
import PrePrimary from '../components/PrePrimary';
import Topbar from '../components/Topbar';
import Calender from '../components/Calender';

const PrePrimaryPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Pre Primary">
            <Topbar />
            <NavOne />
            <PageHeaderPri title="Pre Primary" />
            <PrePrimary />
            <Calender />
            <Footer />
        </Layout>
    );
};

export default PrePrimaryPage;
